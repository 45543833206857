import React from 'react'
import MainTodos from './UseReducer2/MainTodos'

export default function App() {

  return (
    <>
      <MainTodos />
    </>
  )
}
